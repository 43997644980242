import { AxiosInstance } from 'axios'

import { AdminService } from './services/AdminService'

export class AdminApi {
  public readonly admin: AdminService

  constructor(public readonly request: AxiosInstance) {
    this.admin = new AdminService(this.request)
  }
}
