<script setup lang="ts">
import { computed } from 'vue'

import { Box, buildSelectOptionsFromRecord, FormField, Select, Typography } from '@lasso/luikit'

import { AvailabilityStatusENUM } from '@admin/api'

import { useFeaturesForm } from './useFeaturesForm'

const { index } = defineProps<{
  index: number
}>()

const { useFieldArrayModels, submitting, getId } = useFeaturesForm()!

const statusOptions = buildSelectOptionsFromRecord({
  [AvailabilityStatusENUM.NotAvailable]: 'Not available (Disabled for all)',
  [AvailabilityStatusENUM.InPreview]: 'Alpha (Developers Only)',
  [AvailabilityStatusENUM.InternalUse]: 'Beta (Lasso Users Only)',
  [AvailabilityStatusENUM.Available]: 'General Availability',
} satisfies Record<AvailabilityStatusENUM, string>)

const { name, availabilityStatusId: status } = useFieldArrayModels(
  'features',
  ['name', 'availabilityStatusId'],
  () => index,
)

const statusInternal = computed({
  get: () => String(status.value),
  set: (value) => {
    status.value = Number(value)
  },
})
</script>

<template>
  <Box>
    <Typography variant="body1">
      {{ name }}
    </Typography>
  </Box>
  <Box>
    <FormField :id="getId(`features[${index}].availabilityStatusId`)" v-slot="{ id }">
      <Select :id="id" v-model="statusInternal" :options="statusOptions" :disabled="submitting" />
    </FormField>
  </Box>
</template>
