import { reactive } from 'vue'

import { useAccessControlBase, useUser } from '@lasso/shared/hooks'
import { defineRefStore } from '@lasso/shared/utils'

export const useAccessControl = defineRefStore('accessControl', () => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { feature, permission } = useAccessControlBase()
  const { isSuperAdmin } = useUser()

  const permissions = reactive({
    adminOnly: isSuperAdmin,
  })

  const features = reactive({
    adminPagesV2: feature('ADMIN_PAGES_V2'),
  })

  return { permissions, features }
})
