import { computed, ref } from 'vue'
import { array, boolean, mixed, object } from 'yup'

import { useApi, useApiManual, useBetterForm, useToast } from '@lasso/shared/hooks'
import {
  compareNumbers,
  compareStrings,
  createInjectionState,
  objEntries,
  objFromEntries,
  objGroupBy,
} from '@lasso/shared/utils'

import { Feature, PortalSectionsENUM } from '@admin/api'
import { useAdminApi } from '@admin/hooks/useAdminApi'

import { FeatureSections } from './types'

export const [useProvideFeaturesForm, useFeaturesForm] = createInjectionState(() => {
  const api = useAdminApi()
  const toast = useToast()

  const { loading, error, retry, onData } = useApi(api.admin.getFeatures)
  const { requestThrows: updateFeaturesInternal, loading: submitting } = useApiManual(api.admin.updateFeatures)

  const {
    handleSubmit,
    useFieldArray,
    resetForm,
    values,
    initialValues,
    setInitialValue,
    getId,
    useFieldModel,
    useFieldArrayModels,
    dirty,
    isFieldDirty,
  } = useBetterForm({
    validationSchema: object({
      shouldRefresh: boolean().default(false),
      features: array(mixed<Feature>().default({} as Feature)).default([]),
    }),
    initialValues: {
      shouldRefresh: false,
      features: [],
    },
  })

  const featuresField = useFieldArray('features')
  const shouldRefresh = useFieldModel('shouldRefresh')
  const selectedTab = ref<PortalSectionsENUM>(PortalSectionsENUM.General)

  onData((data) => {
    setInitialValue('features', data)
  })

  const features = computed<FeatureSections>(() => {
    const sections = objGroupBy(
      values.features.toSorted((a, b) => compareNumbers(a.sortOrder, b.sortOrder) || compareStrings(a.name, b.name)),
      (feature) => feature.portalSectionId,
    )

    return objFromEntries(
      objEntries(sections).map(([section, features]) => {
        return [section, Map.groupBy(features, (feature) => feature.groupName ?? '')]
      }),
    )
  })

  const featureIndexByKey = computed(() => {
    return objFromEntries(
      featuresField.fields.value.map((feature, index) => {
        return [feature.value.key, index]
      }),
    )
  })

  const getIndex = (key: string) => featureIndexByKey.value[key] ?? -1

  const onReset = () => {
    resetForm({ values: initialValues.value })
  }

  const onSubmit = handleSubmit(async (values) => {
    try {
      const changedFeatures = values.features.filter((feature) => {
        const index = featureIndexByKey.value[feature.key]

        return typeof index === 'number' && isFieldDirty(`features[${index}]`)
      })

      await updateFeaturesInternal(changedFeatures, values.shouldRefresh)

      toast.success('Successfully updated features')

      resetForm({ values })

      if (values.shouldRefresh) {
        window.location.reload()
      }
    } catch (error) {
      toast.error('Failed to update features', error)
    }
  })

  return {
    loading,
    error,
    retry,

    selectedTab,
    features,
    shouldRefresh,
    getIndex,

    getId,
    useFieldArrayModels,
    dirty,
    submitting,
    onReset,
    onSubmit,
  }
})
