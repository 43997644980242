<script setup lang="ts">
import { Box, Paper, Typography } from '@lasso/luikit'

import { PortalSectionsENUM } from '@admin/api'

import FeatureItem from './FeatureItem.vue'
import { useFeaturesForm } from './useFeaturesForm'

const { selectedTab } = defineProps<{
  selectedTab: PortalSectionsENUM
}>()

const { features, getIndex } = useFeaturesForm()!
</script>

<template>
  <Paper p="10" flex col space="8">
    <Box class="features-grid">
      <template v-for="[groupName, group] in features[selectedTab]" :key="groupName">
        <Box v-if="groupName" component="h2" mt="4" class="group-header">
          <Typography variant="lato-h5">
            {{ groupName }}
          </Typography>
        </Box>
        <FeatureItem v-for="feature in group" :key="feature.key" :index="getIndex(feature.key)" />
      </template>
    </Box>
  </Paper>
</template>

<style scoped>
.features-grid {
  @apply gap-4;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(300px, auto) minmax(300px, 1fr);
}

.group-header {
  grid-column: span 2;
}
</style>
