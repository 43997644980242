import { ApiService } from '@lasso/api-shared'

import { Feature } from '../types'

export class AdminService extends ApiService {
  async getFeatures(): Promise<Feature[]> {
    return (
      await this.request({
        url: 'api/feature/',
        method: 'GET',
      })
    ).data
  }

  async updateFeatures(data: Feature[], shouldRefresh: boolean): Promise<unknown> {
    return (
      await this.request({
        url: `api/feature/?refresh=${shouldRefresh}`,
        method: 'POST',
        data,
      })
    ).data
  }
}
