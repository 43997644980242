import { useInitRouterRedirect } from '@lasso/shared/app'
import { useUser } from '@lasso/shared/hooks'

import { useAccessControl } from '@admin/hooks/useAccessControl'

export const useInitRouter = () => {
  const { user } = useUser()
  const accessControl = useAccessControl()

  useInitRouterRedirect((to) => {
    if (!user.value || !accessControl.permissions.adminOnly || !accessControl.features.adminPagesV2) {
      return false
    }

    return to.meta.allowed?.(accessControl, user.value) ?? true
  })
}
