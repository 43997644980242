<script setup lang="ts">
import {
  buildSelectOptionsFromRecord,
  Button,
  Checkbox,
  ErrorState,
  FormChoiceField,
  LayoutLanding,
  Skeleton,
  Tabs,
  TabsItem,
} from '@lasso/luikit'
import { useNavigationGuard } from '@lasso/shared/hooks'

import { PortalSectionsENUM } from '@admin/api'

import FeaturesSection from './FeatureSection.vue'
import { useProvideFeaturesForm } from './useFeaturesForm'

const { shouldRefresh, onSubmit, onReset, submitting, selectedTab, loading, error, retry, getId, dirty } =
  useProvideFeaturesForm()

useNavigationGuard(dirty)

const tabs = buildSelectOptionsFromRecord(
  {
    [PortalSectionsENUM.General]: 'General',
    [PortalSectionsENUM.Campaign]: 'Campaign',
    [PortalSectionsENUM.Creative]: 'Creative',
    [PortalSectionsENUM.Audience]: 'Audience',
    [PortalSectionsENUM.Reporting]: 'Reporting',
    [PortalSectionsENUM.Monetization]: 'Monetization',
  } satisfies Record<PortalSectionsENUM, string>,
  true,
)
</script>

<template>
  <LayoutLanding component="form" @submit="onSubmit">
    <template #title>Feature Preview</template>
    <template v-if="!error" #info>
      <Skeleton v-if="loading" width="185px"></Skeleton>
      <FormChoiceField v-else :id="getId('shouldRefresh')" v-slot="{ id }" label="Refresh client browsers">
        <Checkbox :id="id" v-model="shouldRefresh" :disabled="submitting" />
      </FormChoiceField>
    </template>
    <template v-if="!error" #actions>
      <template v-if="loading">
        <Skeleton width="69px" height="36px" />
        <Skeleton width="55px" height="36px" />
      </template>
      <template v-else>
        <Button size="lg" variant="outlined" :disabled="submitting || !dirty" @click="onReset()">Cancel</Button>
        <Button size="lg" color="primary" :loading="submitting" type="submit">Save</Button>
      </template>
    </template>
    <template v-if="!error" #tabs>
      <Tabs v-model:active="selectedTab" variant="outlined" size="md" color="primary">
        <TabsItem v-for="tab in tabs" :key="tab.value" :value="tab.value">
          {{ tab.label }}
        </TabsItem>
      </Tabs>
    </template>
    <Skeleton v-if="loading" height="1000px" />
    <ErrorState v-else-if="error" :error="error" @retry="retry" />
    <KeepAlive v-else>
      <FeaturesSection :key="selectedTab" :selectedTab="selectedTab" />
    </KeepAlive>
  </LayoutLanding>
</template>
